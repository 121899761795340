/* eslint-disable @next/next/no-img-element */
import Head from 'next/head';
import AboutPageSection1 from '../../components/AboutPage/AboutPageSection1';
import AboutPageSection4 from '../../components/AboutPage/AboutPageSection4';
import AboutPageSection5 from '../../components/AboutPage/AboutPageSection5';
import AboutPageSection6 from '../../components/AboutPage/AboutPageSection6';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import {
  AboutBodyContainer,
  AboutUsWrapper,
  FooterSectionAbout,
} from '../../styles/pageStyles/aboutUsStyle';
import { getUserInfoSSR } from '../../utils/cf';

const About = () => {
  return (
    <div style={{ background: '#0C0C0C' }}>
      <Head>
        <title>About - Driffle</title>

        <meta name="description" content="" />
        <link rel="canonical" href={`https://driffle.com/about`} />
        <meta httpEquiv="last-modified" content={new Date().toDateString()} />

        <meta
          name="description"
          content="Driffle is a digital goods marketplace that connects gamers with worldwide sellers."
        />
        <meta name="robots" content="index,follow" />

        {/* OG Meta */}
        <meta property="og:title" content="About | Driffle" />
      </Head>
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
        <Header type="about" headerColor="translucent" />
        <Navbar type="about" />
      </div>

      <AboutUsWrapper>
        <AboutBodyContainer>
          <section>
            <AboutPageSection1 />
          </section>
          {/* <section>
            <AboutPageSection3 />
          </section> */}
          <section>
            <AboutPageSection4 />
          </section>
          <section>
            <AboutPageSection6 />
          </section>
          <section>
            <AboutPageSection5 />
          </section>
          {/* <section>
            <AboutPageSection2 />
          </section> */}
          <FooterSectionAbout
            style={{
              minHeight: 'fit-content',
              height: 'fit-content',
            }}
          >
            <Footer />
          </FooterSectionAbout>
        </AboutBodyContainer>
      </AboutUsWrapper>
    </div>
  );
};

export default About;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
