import { Popover } from '@mui/material';
// import DOMPurify from 'dompurify';
import Link from 'next/dist/client/link';
import { useRouter } from 'next/router';
import { memo, useState } from 'react';
import { useLocale } from '../../contexts/LocalizationContext';
import {
  AboutSection1,
  AboutSection1Content,
  AboutSection1ContentCaption,
  AboutSection1ContentTitle,
  AboutSection1Gradient1,
  BackdropBg,
  BlurBg,
  HoverProductCardContainer,
  HoverProductCardContainerBg,
  HoverProductCardWrapper,
  ProductCardDetails,
  ProductCardImg,
  ProductCardTitle,
  RotatingItem500,
  RotatingItem500B,
  RotatingItem700,
  RotatingItem700B,
  RotatingItem700C,
  RotatingItem900,
  RotatingItem900B,
  RotatingItem900C,
  RotationAnimationContainer,
  RotationAnimationWrapper,
  VisitOurStoreBtn,
} from '../../styles/pageStyles/aboutUsStyle';
import { BackgroundIcon, MaskIcon } from '../Icons/Icons';
import { exportPlatformIcon } from '../Icons/PlatformIcons';

const productsArray = [
  {
    title: 'Marvel’s Spider-Man Remastered (PC) - Steam - Digital Code',
    link: 'https://driffle.com/marvels-spider-man-remastered-pc-steam-digital-code-p9879086',
    platform: 'Steam',
    productRegion: 'GLOBAL',
    coverImage:
      'https://static.driffle.com/media-gallery/prod/166176373460593300_marvels-spiderman-rm.webp',
  },
  {
    title: 'Mortal Kombat 11 (PC) - Steam - Digital Code',
    link: 'https://driffle.com/mortal-kombat-11-steam-cd-key-p7995',
    platform: 'Steam',
    productRegion: 'GLOBAL',
    coverImage:
      'https://static.driffle.com/media-gallery/prod/167050239633864900_mortal-kombat-11.webp',
  },
  {
    title: 'Xbox $25 Gift Card (US) - Digital Code',
    link: 'https://driffle.com/xbox-live-25-prepaid-card-us-p67512',
    platform: 'Xbox Live',
    productRegion: 'UNITED STATES',
    coverImage: 'https://static.driffle.com/images/xbox-gift-card-usd-25.webp',
  },
  {
    title: 'Batman: Arkham Knight (PC) - Steam - Digital Code',
    link: 'https://driffle.com/batman-arkham-knight-steam-cd-key-p629040',
    platform: 'Steam',
    productRegion: 'GLOBAL',
    coverImage:
      'https://static.driffle.com/products/batman-arkham-knight-steam-cd-key-p629040-cover.webp',
  },
  {
    title:
      'Minecraft: Java and Bedrock Edition (PC) - Microsoft Store - Digital Code',
    link: 'https://driffle.com/minecraft-java-bedrock-edition-pc-microsoft-store-digital-code-p9879164',
    platform: 'Microsoft Store',
    productRegion: 'GLOBAL',
    coverImage:
      'https://static.driffle.com/fit-in/360x256/media-gallery/prod/167628706253051500_minecraft-jvbd.webp',
  },
  {
    title: 'FIFA 23 (PC) - Origin - Digital Code',
    link: 'https://driffle.com/fifa-23-pc-origin-digital-code-p9880177',
    platform: 'EA Play',
    productRegion: 'GLOBAL',
    coverImage:
      'https://static.driffle.com/media-gallery/prod/166453636405767800_fifa-23.webp',
  },
  {
    title:
      'Grand Theft Auto V: Premium Online Edition (PC) - Rockstar - Digital Code',
    link: 'https://driffle.com/grand-theft-auto-v-premium-online-edition-rockstar-digital-download-cd-key-p40945',
    platform: 'Rockstar Games',
    productRegion: 'GLOBAL',
    coverImage:
      'https://static.driffle.com/images/2f398399-fe1a-4364-8943-56f1ccb5b735.webp',
  },
  {
    title: 'Steam Wallet INR 1000 Gift Card (India) - Digital Code',
    link: 'https://driffle.com/steam-gift-card-1000-inr-activation-code-p694284',
    platform: 'Steam',
    productRegion: 'INDIA',
    coverImage:
      'https://static.driffle.com/images/48ea6e67-e17b-447c-89c9-d9f08d4354e3.webp',
  },
];

export const HoverProductCard = (props: {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: any;
  hoveredProductIndex: number;
}) => {
  const router = useRouter();
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      sx={{
        pointerEvents: 'none',
        zIndex: 100,
      }}
      PaperProps={{
        style: { borderRadius: '16px' },
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      onClose={props.handleClose}
      disablePortal
      disableEnforceFocus
      disableRestoreFocus
    >
      <HoverProductCardWrapper>
        <HoverProductCardContainer
          onClick={() =>
            router.push(productsArray[props.hoveredProductIndex].link)
          }
        >
          <HoverProductCardContainerBg>
            <ProductCardImg
              src={productsArray[props.hoveredProductIndex].coverImage}
              alt="title"
            />
            <ProductCardTitle>
              {productsArray[props.hoveredProductIndex].title}
            </ProductCardTitle>
            <ProductCardDetails>
              {exportPlatformIcon(
                productsArray[props.hoveredProductIndex].platform,
                '16px',
                '16px',
                '0 8px 0 0'
              )}
              <p> {productsArray[props.hoveredProductIndex].productRegion}</p>
            </ProductCardDetails>
          </HoverProductCardContainerBg>
        </HoverProductCardContainer>
      </HoverProductCardWrapper>
    </Popover>
  );
};

function AboutPageSection1() {
  const router = useRouter();

  const { messages } = useLocale();
  const {
    visit_our_store_msg,
    driffle_one_stop_shop_msg,
    driffle_company_offering_digital_msg,
  } = messages || {};

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [animationState, setAnimationState] = useState('running');
  const [hoveredProduct, setHoveredProduct] = useState<number | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setAnimationState('paused');
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setAnimationState('running');
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {open && <BackdropBg />}
      <AboutSection1>
        <AboutSection1Content>
          {hoveredProduct !== null && (
            <HoverProductCard
              open={open}
              anchorEl={anchorEl}
              handleClose={handlePopoverClose}
              hoveredProductIndex={hoveredProduct}
            />
          )}
          <RotationAnimationWrapper style={{ zIndex: 5 }}>
            <RotationAnimationContainer radius={500}>
              <RotatingItem500
                onMouseEnter={(e: any) => {
                  handlePopoverOpen(e);
                  setHoveredProduct(6);
                }}
                onClick={() =>
                  router.push(productsArray[hoveredProduct ?? 0].link)
                }
                onMouseLeave={handlePopoverClose}
                style={{ animationPlayState: animationState }}
              >
                <BackgroundIcon
                  url="/tiles/about/gta.png"
                  margin="0 0"
                  width="40px"
                  height="40px"
                />
              </RotatingItem500>
              <RotatingItem500B
                onMouseEnter={(e: any) => {
                  handlePopoverOpen(e);
                  setHoveredProduct(7);
                }}
                onClick={() =>
                  router.push(productsArray[hoveredProduct ?? 0].link)
                }
                onMouseLeave={handlePopoverClose}
                style={{ animationPlayState: animationState }}
              >
                <BackgroundIcon
                  url="/tiles/about/steam.png"
                  margin="0 0"
                  width="40px"
                  height="40px"
                />
              </RotatingItem500B>
            </RotationAnimationContainer>
          </RotationAnimationWrapper>
          <RotationAnimationWrapper style={{ zIndex: 4 }}>
            <RotationAnimationContainer radius={700}>
              <RotatingItem700
                onMouseEnter={(e: any) => {
                  handlePopoverOpen(e);
                  setHoveredProduct(3);
                }}
                onClick={() =>
                  router.push(productsArray[hoveredProduct ?? 0].link)
                }
                onMouseLeave={handlePopoverClose}
                style={{ animationPlayState: animationState }}
              >
                <BackgroundIcon
                  url="/tiles/about/batman.png"
                  margin="0 0"
                  width="40px"
                  height="40px"
                />
              </RotatingItem700>
              <RotatingItem700B
                onMouseEnter={(e: any) => {
                  handlePopoverOpen(e);
                  setHoveredProduct(5);
                }}
                onClick={() =>
                  router.push(productsArray[hoveredProduct ?? 0].link)
                }
                onMouseLeave={handlePopoverClose}
                style={{ animationPlayState: animationState }}
              >
                <BackgroundIcon
                  url="/tiles/about/fifa.png"
                  margin="0 0"
                  width="40px"
                  height="40px"
                />
              </RotatingItem700B>
              <RotatingItem700C
                onMouseEnter={(e: any) => {
                  handlePopoverOpen(e);
                  setHoveredProduct(0);
                }}
                onClick={() =>
                  router.push(productsArray[hoveredProduct ?? 0].link)
                }
                onMouseLeave={handlePopoverClose}
                style={{ animationPlayState: animationState }}
              >
                <BackgroundIcon
                  url="/tiles/about/spiderman.png"
                  margin="0 0"
                  width="40px"
                  height="40px"
                />
              </RotatingItem700C>
            </RotationAnimationContainer>
          </RotationAnimationWrapper>
          <RotationAnimationWrapper style={{ zIndex: 3 }}>
            <RotationAnimationContainer radius={900}>
              <RotatingItem900
                onMouseEnter={(e: any) => {
                  handlePopoverOpen(e);
                  setHoveredProduct(4);
                }}
                onClick={() =>
                  router.push(productsArray[hoveredProduct ?? 0].link)
                }
                onMouseLeave={handlePopoverClose}
                style={{ animationPlayState: animationState }}
              >
                <BackgroundIcon
                  url="/tiles/about/minecraft.png"
                  margin="0 0"
                  width="40px"
                  height="40px"
                />
              </RotatingItem900>
              <RotatingItem900B
                onMouseEnter={(e: any) => {
                  handlePopoverOpen(e);
                  setHoveredProduct(1);
                }}
                onClick={() =>
                  router.push(productsArray[hoveredProduct ?? 0].link)
                }
                onMouseLeave={handlePopoverClose}
                style={{ animationPlayState: animationState }}
              >
                <BackgroundIcon
                  url="/tiles/about/mortalkombat.png"
                  margin="0 0"
                  width="40px"
                  height="40px"
                />
              </RotatingItem900B>
              <RotatingItem900C
                onMouseEnter={(e: any) => {
                  handlePopoverOpen(e);
                  setHoveredProduct(2);
                }}
                onClick={() =>
                  router.push(productsArray[hoveredProduct ?? 0].link)
                }
                onMouseLeave={handlePopoverClose}
                style={{ animationPlayState: animationState }}
              >
                <BackgroundIcon
                  url="/tiles/about/xbox.png"
                  margin="0 0"
                  width="40px"
                  height="40px"
                />
              </RotatingItem900C>
            </RotationAnimationContainer>
          </RotationAnimationWrapper>
          <AboutSection1Gradient1 />
          <BlurBg />
          <AboutSection1ContentTitle
            dangerouslySetInnerHTML={{
              __html: driffle_one_stop_shop_msg ?? '',
            }}
          />

          <AboutSection1ContentCaption>
            {driffle_company_offering_digital_msg}
          </AboutSection1ContentCaption>
          <Link passHref prefetch={false} href="/">
            <a style={{ zIndex: 12 }}>
              <VisitOurStoreBtn>
                <p>{visit_our_store_msg}</p>
                <MaskIcon
                  id="visit-store-arrow-right"
                  url="/icons/arrow-back-24.svg"
                  height="20px"
                  width="20px"
                  margin="0 0 0 -16px"
                  selected
                  color="#fff"
                />
              </VisitOurStoreBtn>
            </a>
          </Link>
        </AboutSection1Content>
      </AboutSection1>
    </>
  );
}

export default memo(AboutPageSection1);
