import { memo } from 'react';
import { COLORS } from '../../constants/colors';

import {
  AboutSection3Card,
  AboutSection3CardMobileContainer,
  AboutSection3CardWebContainer,
  AboutSectionWhyUs,
  AboutSectionWhyUsCardDesc,
  AboutSectionWhyUsCardTitle,
  AboutSectionWhyUsCardWrapper,
  AboutSectionWhyUsContainer,
  AboutSectionWhyUsContent,
  AboutSectionWhyUsDesc,
  AboutSectionWhyUsTitle,
} from '../../styles/pageStyles/aboutUsStyle';
import { MaskIcon } from '../Icons/Icons';

import { useLocale } from '../../contexts/LocalizationContext';




function AboutPageSection6() {
  
  const {messages} = useLocale();
  const {
    sell_on_driffle_msg,
    we_are_dedicated_to_empowering_sellers_msg,
    lowest_commissions_msg,
    api_msg,
    gamer_audience_boost_msg,
    instant_payouts_msg,
    marketing_support_msg,
    we_offer_the_lowest_available_msg,
    driffle_offers_a_free_api_msg,
    driffle_connects_you_with_a_msg,
    get_instant_settlements_as_your_msg,
    driffle_enhances_your_promotion_for_msg
  } = messages || {};

  const sellOnDriffleList = [
    {
      title: lowest_commissions_msg,
      desc: we_offer_the_lowest_available_msg,
      icon: '/icons/about/euro_symbol.svg',
    },
    {
      title: api_msg,
      desc: driffle_offers_a_free_api_msg,
      icon: '/icons/about/code.svg',
    },
    {
      title: gamer_audience_boost_msg,
      desc: driffle_connects_you_with_a_msg,
      icon: '/icons/about/target.svg',
    },
    {
      title: instant_payouts_msg,
      desc: get_instant_settlements_as_your_msg,
      icon: '/icons/about/instant-payout-24.svg',
    },
    {
      title: marketing_support_msg,
      desc: driffle_enhances_your_promotion_for_msg,
      icon: '/icons/about/market_support.svg',
    },
  ];
  return (
    <AboutSectionWhyUs>
      <AboutSectionWhyUsContainer>
        <AboutSectionWhyUsContent>
          <AboutSectionWhyUsTitle>{sell_on_driffle_msg}</AboutSectionWhyUsTitle>
          <AboutSectionWhyUsDesc>
            {/* We&apos;re dedicated to empowering sellers with seamless
            opportunities to showcase their games and digital goods to a wider
            audience. */}
            {we_are_dedicated_to_empowering_sellers_msg}
          </AboutSectionWhyUsDesc>
          <AboutSection3CardWebContainer>
            <AboutSectionWhyUsCardWrapper>
              {sellOnDriffleList.map((item, index) => {
                return (
                  <AboutSection3Card key={index}>
                    <AboutSectionWhyUsCardTitle>
                      <MaskIcon
                        url={item.icon}
                        margin="0 6px 0 0"
                        height="24px"
                        width="24px"
                        selected
                        color={COLORS.white}
                      />{' '}
                      {item.title}
                    </AboutSectionWhyUsCardTitle>
                    <AboutSectionWhyUsCardDesc>
                      {item.desc}
                    </AboutSectionWhyUsCardDesc>
                  </AboutSection3Card>
                );
              })}
            </AboutSectionWhyUsCardWrapper>
          </AboutSection3CardWebContainer>
          <AboutSectionWhyUsCardWrapper>
            {sellOnDriffleList.map((item, index) => {
              return (
                index % 2 === 0 && (
                  <AboutSection3CardMobileContainer key={index}>
                    <AboutSection3Card>
                      <AboutSectionWhyUsCardTitle>
                        <MaskIcon
                          url={sellOnDriffleList[index].icon}
                          margin="0 6px 0 0"
                          height="24px"
                          width="24px"
                          selected
                          color={COLORS.white}
                        />{' '}
                        {sellOnDriffleList[index].title}
                      </AboutSectionWhyUsCardTitle>
                      <AboutSectionWhyUsCardDesc>
                        {sellOnDriffleList[index].desc}
                      </AboutSectionWhyUsCardDesc>
                    </AboutSection3Card>
                    {sellOnDriffleList[index + 1]?.icon && (
                      <AboutSection3Card>
                        <AboutSectionWhyUsCardTitle>
                          <MaskIcon
                            url={sellOnDriffleList[index + 1]?.icon}
                            margin="0 6px 0 0"
                            height="24px"
                            width="24px"
                            selected
                            color={COLORS.white}
                          />{' '}
                          {sellOnDriffleList[index + 1]?.title}
                        </AboutSectionWhyUsCardTitle>
                        <AboutSectionWhyUsCardDesc>
                          {sellOnDriffleList[index + 1]?.desc}
                        </AboutSectionWhyUsCardDesc>
                      </AboutSection3Card>
                    )}
                  </AboutSection3CardMobileContainer>
                )
              );
            })}
          </AboutSectionWhyUsCardWrapper>
        </AboutSectionWhyUsContent>
      </AboutSectionWhyUsContainer>
    </AboutSectionWhyUs>
  );
}

export default memo(AboutPageSection6);
