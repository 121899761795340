import { memo } from 'react';
import {
  AboutSectionInvestors,
  AboutSectionInvestorsCard,
  AboutSectionInvestorsCards,
  AboutSectionInvestorsContainer,
  AboutSectionInvestorsTitle,
} from '../../styles/pageStyles/aboutUsStyle';
import { useLocale } from '../../contexts/LocalizationContext';


function AboutPageSection5() {
   
  const {messages} = useLocale();
  const {
    our_investors_msg
  } = messages || {}


  return (
    <AboutSectionInvestors>
      <AboutSectionInvestorsContainer>
        <AboutSectionInvestorsTitle>{our_investors_msg}</AboutSectionInvestorsTitle>
        <AboutSectionInvestorsCards>
          <AboutSectionInvestorsCard
            src="/images/investors/Beenext.png"
            alt="Beenext"
          />
          <AboutSectionInvestorsCard
            src="/images/investors/Better.png"
            alt="Better"
          />
          <AboutSectionInvestorsCard
            src="/images/investors/Jafco-asia.png"
            alt="Jafco-asia"
          />
          <AboutSectionInvestorsCard
            src="/images/investors/WhiteVenture.png"
            alt="WhiteVenture"
          />
          <AboutSectionInvestorsCard
            src="/images/investors/Taurus.png"
            alt="Taurus"
          />
        </AboutSectionInvestorsCards>
      </AboutSectionInvestorsContainer>
    </AboutSectionInvestors>
  );
}

export default memo(AboutPageSection5);
