import { memo } from 'react';
import { COLORS } from '../../constants/colors';
import { useLocale } from '../../contexts/LocalizationContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {
  AboutSection3Card,
  AboutSection3CardMobileContainer,
  AboutSection3CardWebContainer,
  AboutSectionWhyUs,
  AboutSectionWhyUsCardDesc,
  AboutSectionWhyUsCardTitle,
  AboutSectionWhyUsCardWrapper,
  AboutSectionWhyUsContainer,
  AboutSectionWhyUsContent,
  AboutSectionWhyUsDesc,
  AboutSectionWhyUsTitle,
} from '../../styles/pageStyles/aboutUsStyle';
import { MaskIcon } from '../Icons/Icons';

function AboutPageSection4() {
  const { width } = useWindowDimensions();

  const { messages } = useLocale();
  const {
    buy_on_driffle_msg,
    we_are_on_a_mission_to_msg,
    best_prices_msg,
    buy_goods_in_seconds_msg,
    your_country_your_currency_msg,
    diverse_payment_methods_msg,
    customer_satisfaction_msg,
    get_premium_games_at_the_msg,
    buy_goods_in_a_flash_msg,
    buying_goods_in_other_currencies_msg,
    your_convenience_is_our_priority_msg,
    we_have_got_you_covered_in_msg,
  } = messages || {};

  const buyOnDriffleList = [
    {
      title: best_prices_msg,
      desc: get_premium_games_at_the_msg,
      icon: '/icons/about/attach_money.svg',
    },
    {
      title: buy_goods_in_seconds_msg,
      desc: buy_goods_in_a_flash_msg,
      icon: '/icons/globe-icon-24.svg',
    },
    {
      title: your_country_your_currency_msg,
      desc: buying_goods_in_other_currencies_msg,
      icon: '/icons/thunder-icon-24.svg',
    },
    {
      title: diverse_payment_methods_msg,
      desc: your_convenience_is_our_priority_msg,
      icon: '/icons/about/diverse_payment_methods.svg',
    },
    {
      title: customer_satisfaction_msg,
      desc: we_have_got_you_covered_in_msg,
      icon: '/icons/about/customer_satisfaction.svg',
    },
  ];
  return (
    <AboutSectionWhyUs>
      <AboutSectionWhyUsContainer>
        <AboutSectionWhyUsContent>
          <AboutSectionWhyUsTitle>{buy_on_driffle_msg}</AboutSectionWhyUsTitle>
          <AboutSectionWhyUsDesc>
            {/* We&apos;re on a mission to provide seamless access to games and
            digital goods for everyone. */}
            {we_are_on_a_mission_to_msg}
          </AboutSectionWhyUsDesc>
          <AboutSection3CardWebContainer>
            <AboutSectionWhyUsCardWrapper>
              {buyOnDriffleList.map((item, index) => {
                return (
                  <AboutSection3Card key={index}>
                    <AboutSectionWhyUsCardTitle>
                      <MaskIcon
                        url={item.icon}
                        margin="0 6px 0 0"
                        height="24px"
                        width="24px"
                        selected
                        color={COLORS.white}
                      />{' '}
                      {item.title}
                    </AboutSectionWhyUsCardTitle>
                    <AboutSectionWhyUsCardDesc>
                      {item.desc}
                    </AboutSectionWhyUsCardDesc>
                  </AboutSection3Card>
                );
              })}
            </AboutSectionWhyUsCardWrapper>
          </AboutSection3CardWebContainer>
          <AboutSectionWhyUsCardWrapper>
            {buyOnDriffleList.map((item, index) => {
              return (
                index % 2 === 0 && (
                  <AboutSection3CardMobileContainer key={index}>
                    <AboutSection3Card>
                      <AboutSectionWhyUsCardTitle>
                        <MaskIcon
                          url={buyOnDriffleList[index].icon}
                          margin="0 6px 0 0"
                          height="24px"
                          width="24px"
                          selected
                          color={COLORS.white}
                        />{' '}
                        {buyOnDriffleList[index].title}
                      </AboutSectionWhyUsCardTitle>
                      <AboutSectionWhyUsCardDesc>
                        {buyOnDriffleList[index].desc}
                      </AboutSectionWhyUsCardDesc>
                    </AboutSection3Card>
                    {buyOnDriffleList[index + 1]?.icon && (
                      <AboutSection3Card>
                        <AboutSectionWhyUsCardTitle>
                          <MaskIcon
                            url={buyOnDriffleList[index + 1]?.icon}
                            margin="0 6px 0 0"
                            height="24px"
                            width="24px"
                            selected
                            color={COLORS.white}
                          />{' '}
                          {buyOnDriffleList[index + 1]?.title}
                        </AboutSectionWhyUsCardTitle>
                        <AboutSectionWhyUsCardDesc>
                          {buyOnDriffleList[index + 1]?.desc}
                        </AboutSectionWhyUsCardDesc>
                      </AboutSection3Card>
                    )}
                  </AboutSection3CardMobileContainer>
                )
              );
            })}
          </AboutSectionWhyUsCardWrapper>
        </AboutSectionWhyUsContent>
      </AboutSectionWhyUsContainer>
    </AboutSectionWhyUs>
  );
}

export default memo(AboutPageSection4);
